const url = new URL(window.location.href); // Use window.location.href to get the current URL
const params = new URLSearchParams(url.search);

const debugMode = !!params.get("debugMode");
const pushToDataLayer = (objectToPush) => {
    if (debugMode) {
        console.group("object to push to datalayer");
        console.log(objectToPush);
        console.groupEnd();
    }
    if (typeof window.dataLayer === "undefined") {
        console.error("no datalayer");
        return;
    }
    window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    window.dataLayer.push(objectToPush);
}

const _handlePageLoadForTypeVisitOverviewPage = (parameters) => {
    if (parameters.pageType.toString() !== '100') {
        return;
    }
    //user visits overview page
};

const _handlePageLoadForTypeVisitDetailPage = (parameters) => {
    if (parameters.pageType.toString() !== '101') {
        return;
    }
    if (debugMode) {
        console.log("executed visit-of-detail handler");
    }

    //user visits detail page
    pushToDataLayer({
        event: "view_item",
        ecommerce: {
            currency: "EUR",
            value: "",
            items: [
                {
                    item_id: parameters.spId,
                    item_name: parameters.spName,
                    affiliation: "",
                    coupon: "",
                    discount: "",
                    index: 0,
                    item_brand: "",
                    item_category: parameters.spTown,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: "",
                    location_id: "",
                    price: "",
                    quantity: 1
                }
            ]
        }
    });

};

const _handleBookingAccomplished = (parameters) => {
    if (parameters.pageType.toString() !== '9901') {
        return;
    }

    if (debugMode) {
        console.log("executed booking-accomplished-handler");
    }

    const accomodationItemItems = parameters.bookingConfirmation.items.accommodations.map(accomodationItem => {
        return {
            item_id: accomodationItem.serviceProviderId,
            item_name: accomodationItem.serviceProvider.name,
            affiliation: "",
            coupon: parameters.bookingConfirmation.guestSettlerCode,
            discount: "",
            index: 0,
            item_brand: "",
            item_category: accomodationItem.serviceProvider.location?.town,
            item_category2: "",
            item_category3: "",
            item_category4: "",
            item_category5: "",
            item_list_id: "",
            item_list_name: "",
            item_variant: "green",
            location_id: "",
            price: accomodationItem.price,
            quantity: accomodationItem.units
        }
    });

    pushToDataLayer({
        event: "purchase",
        ecommerce: {
            transaction_id: parameters.bookingConfirmation.transactionId,
            value: parameters.bookingConfirmation.totalAmount,
            tax: parameters.bookingConfirmation.visitorTax,
            shipping: "",
            currency: "EUR",
            coupon: parameters.bookingConfirmation.guestSettlerCode,
            items: accomodationItemItems
        }
    });
}

const pageLoadHandlers = [
    _handlePageLoadForTypeVisitOverviewPage,
    _handlePageLoadForTypeVisitDetailPage,
    _handleBookingAccomplished
];


const _handleUserActionTypeAddToCart = (parameters) => {
    if (parameters.type.toString() !== "10") {
        return;
    }

    if (debugMode) {
        console.log("executed add-to-cart handler");
    }

    const item = parameters.item;
    let totalPrice = item.price.totalPrice;
    let coupon = false;
    let discount = "";
    if (item.price.hasPromotionCodeApplied) {
        totalPrice = item.price.promotionCodePrice;
        discount = item.price.totalPrice - item.price.promotionCodePrice;
        coupon = true;
    }

    pushToDataLayer({
        event: "add_to_cart",
        number_adults: item.occupancy.adults,
        number_children: item.occupancy.children.childrenAges.length ?? 0,
        ecommerce: {
            currency: "EUR",
            value: totalPrice,
            items: [
                {
                    item_id: item.serviceProvider.id,
                    item_name: item.serviceProvider.name,
                    affiliation: "",
                    coupon: coupon,
                    discount: discount,
                    index: 0,
                    item_brand: "",
                    item_category: item.serviceProvider.location?.town,
                    item_category2: item.serviceProvider.location?.district,
                    item_category3: item.serviceProvider.stars?.name,
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "",
                    item_list_name: "",
                    item_variant: item.product.name,
                    location_id: "",
                    price: totalPrice,
                    quantity: item.units
                }
            ]
        }
    });
}

const _handleUserActionRequestForm = (parameters) => {
    if (parameters.type.toString() !== "1" && parameters.type.toString() !== "2") {
        return;
    }

    if (parameters.requestType.toString() !== "4") {
        return;
    }

    if (debugMode) {
        console.log("executed request-form handler");
    }
    pushToDataLayer({
        event: "anfrage_unterkunft",
        unterkunft_id: parameters.id,
        accommodationName: parameters.name,
    });
}

const _handleUserActionClickOnLandlordWebsite = (parameters) => {
    if (parameters.type.toString() !== "4") {
        return;
    }
    if (debugMode) {
        console.log("executed request-form handler");
    }
    pushToDataLayer({
        event: "website_unterkunft",
        unterkunft_id: parameters.parentId,
        accommodationName: parameters.parentName,
    });
}

const _handleUserActionClickOnLandlordPhone = (parameters) => {
    if (parameters.type.toString() !== "0") {
        return;
    }
    if (debugMode) {
        console.log("executed request-form handler");
    }
    pushToDataLayer({
        event: "telefon_unterkunft",
        unterkunft_id: parameters.parentId,
        accommodationName: parameters.parentName,
    });
}


const userActionHandlers = [
    _handleUserActionTypeAddToCart,
    _handleUserActionRequestForm,
    _handleUserActionClickOnLandlordWebsite,
    _handleUserActionClickOnLandlordPhone
];


export function handleToscEventOnPageLoad(parameters) {
    if (debugMode) {
        console.group('handle onPageLoad event');
        console.group("parameters")
        console.log(parameters);
        console.groupEnd();
        console.group("called handlers")
    }

    pageLoadHandlers.forEach(handler => handler(parameters));

    if (debugMode) {
        console.groupEnd();
        console.groupEnd();
    }
}

export function handleToscEventOnUserAction(parameters) {
    if (debugMode) {
        console.group('handle userAction event');
        console.group("parameters")
        console.log(parameters);
        console.groupEnd();
        console.group("called handlers")
    }
    userActionHandlers.forEach(handler => handler(parameters));
    if (debugMode) {
        console.groupEnd();
        console.groupEnd();
    }
}

