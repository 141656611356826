"use strict";

export function initInScope($scope){
    const bgColor = $scope.find('.ribbon-bg-color-picker')[0];
    const bgColorInput = $scope.find('.bg-color-input')[0];
    const textColor =  $scope.find('.ribbon-text-color-picker')[0];
    const textColorInput = $scope.find('.text-color-input')[0];

    $(window).on('load', function () {
        bgColor.value = bgColorInput.innerHTML.substr(0,7);
        textColor.value = textColorInput.innerHTML.substr(0,7);

        bgColorInput.addEventListener('click', function() {
            bgColor.value = bgColorInput.innerHTML.substr(0,7);
        })
        bgColor.addEventListener('change', function(evt) {
            bgColorInput.innerHTML = bgColor.value;
        })
        textColorInput.addEventListener('click', function() {
            console.log(textColorInput.innerHTML);
            textColor.value = textColorInput.innerHTML.substr(0,7);
        })
        textColor.addEventListener('change', function(evt) {
            textColorInput.innerHTML = textColor.value;
        })
    });
}