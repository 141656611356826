import { debounce } from "debounce";

export function initInScope($scope) {
    if (matchMedia('hover: none) and (pointer: coarse)').matches) {
        return;
    }

    $scope.find('.js-drag-indicator').each(function () {
        let $area = $(this);
        let $element = $area.find('.js-drag-indicator__element');
        let $ignoredAreas = $area.find('.js-drag-indicator__ignore-area, .slick-dots');
        let areaRect = $area[0].getBoundingClientRect();

        $(window).on('scroll', debounce(function () {
            areaRect = $area[0].getBoundingClientRect()
        }, 200));

        $area.on('mouseenter', () => {
            $element.addClass('is-following');
            $area.addClass('is-dragging');
            areaRect = $area[0].getBoundingClientRect();
        }).on('mouseleave', () => {
            $element.removeClass('is-following');
            $area.removeClass('is-dragging');
        });

        // initial
        $area.one('mousemove', () => {
            $element.addClass('is-following');
        });

        $ignoredAreas.on('mouseenter', () => {
            $element.removeClass('is-following');
            $area.removeClass('is-dragging');
        }).on('mouseleave', () => {
            $element.addClass('is-following');
        });

        $area.on('mousemove', (evt) => {
            $element.css({
                'transform': `translate(${evt.clientX - areaRect.left}px, ${evt.clientY - areaRect.top}px)`
            })
        });

        $area.on('mousedown', () => {
            $element.addClass('is-grabbed');
        });
        $area.on('mouseup', () => {
            $element.removeClass('is-grabbed');
        });
    })
}