"use strict";

import Parallax from 'parallax-js';

let didScroll;

export function initInScope ($scope) {
    if (matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    $scope.find('.js-parallax-img').each(function () {
        let $this = $(this);

        let parallaxInstance = new Parallax($(this)[0], {
            clipRelativeInput: true
        });


        $(window).scroll(function () {
            didScroll = true;
        });

        requestAnimationFrame(function animationFrameHandler() {
            if (didScroll) {
                scrollHandler($this, parallaxInstance);

                didScroll = false;
            }
            requestAnimationFrame(animationFrameHandler);
        });
    });
}

function scrollHandler($this, parallaxInstance) {
    if($(window).scrollTop() > $this.offset().top + $this.outerHeight()) {
        parallaxInstance.disable();
    } else if($(window).scrollTop() <= $this.offset().top + $this.outerHeight()) {
        parallaxInstance.enable();
    }
}