import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';

// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'jquery/dist/jquery.min.js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as loislWidget from './scripts/loisl-widget';
app.modules.loislWidget = loislWidget.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

localStorage.removeItem('cookie-permissions', null);

import * as toggleOffscreen from './scripts/toggle-offscreen';
app.modules.toggleOffscreen = toggleOffscreen.initInScope;

import * as navToggle from './scripts/nav-toggle';
app.navToggle = navToggle.init;

import * as toggleNavLevel from './scripts/toggle-navlevel';
app.modules.toggleNavLevel = toggleNavLevel.initInScope;

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as nav from '@elements/accessibility-nav';
app.modules.accessibilityNav = nav.createInitInScope('.js-touch-nav', '.js-touch-nav__item');

import * as affixNavbar from './scripts/affix-navbar';
app.affixNavbar = affixNavbar.init;

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope

import brochures from './scripts/brochures'
import customIframes from './scripts/customIframes'

app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev slider__arrow d-none d-md-block text-primary" aria-label="Previous"></button>',
    nextArrow: '<button type="button" class="slick-next slider__arrow d-none d-md-block text-primary" aria-label="Next"></button>',
    dotsClass: 'sr-only',
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: "80px"
            }
        }
    ]
});

app.modules.imgTeaserSlider = slider.createInitInScope('.js-img-teaser-slider', {
    slidesToShow: 4.275,
    dotsClass: 'sr-only',
    swipeToSlide: true,
    touchThreshold: 8,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2.8
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.5
            }
        }
    ]
});


import * as circleSlider from './scripts/circle-slider';
app.modules.circleSlider = circleSlider.initInScope;

import * as sliderWithThumbs from './scripts/slider-with-thumbs';
app.modules.sliderWithThumbs = sliderWithThumbs.initInScope;

import * as dragIndicator from './scripts/drag-indicator';
app.modules.dragIndicator = dragIndicator.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});

import * as ajaxFormExtend from './scripts/ajax-form-extend'
app.modules.ajaxFormExtend = ajaxFormExtend.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as svgPercentage from './scripts/svg-percentage';
app.modules.svgPercentage = svgPercentage.initInScope;

import * as formValidator from './scripts/form-validator';
app.modules.formValidator = formValidator.initInScope;

import * as parallaxImages from './scripts/parallax-images';
app.modules.parallaxImages = parallaxImages.initInScope;

import * as googleMapDirection from './scripts/google-map-direction';
app.modules.googleMapDirection = googleMapDirection.initInScope;

import * as selectTab from './scripts/select-tab';
app.modules.selectTab = selectTab.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as quickFinder from './scripts/quick-finder';
app.modules.quickFinderSmall = quickFinder.createInitInScope('.js-quickfinder--sm', {},
    (data) => `<div class="js-quickfinder__childs row">${Object.keys(data).map(function (key) {
        return '<div class="col-md-6">' +
            '<div class="select form-group"><label class="form-control-label floating-label" for="' + data[key].name + data[key].id + '">Kind ' + data[key].id + '</label>' +
            '<select name="ca0[]" id="' + data[key].name + data[key].id + '" name="' + data[key].name + data[key].id + '" class="form-control js-floating-label">' +
            '<option value="0" selected="">&lt;1 Jahre</option>' +
            '<option value="1">1 Jahre</option>' +
            '<option value="2">2 Jahre</option>' +
            '<option value="3">3 Jahre</option>' +
            '<option value="4">4 Jahre</option>' +
            '<option value="5">5 Jahre</option>' +
            '<option value="6">6 Jahre</option>' +
            '<option value="7">7 Jahre</option>' +
            '<option value="8">8 Jahre</option>' +
            '<option value="9">9 Jahre</option>' +
            '<option value="10">10 Jahre</option>' +
            '<option value="11">11 Jahre</option>' +
            '<option value="12">12 Jahre</option>' +
            '<option value="13">13 Jahre</option>' +
            '<option value="14">14 Jahre</option>' +
            '<option value="15">15 Jahre</option>' +
            '<option value="16">16 Jahre</option>' +
            '<option value="17">17 Jahre</option>' +
            '</select></div></div>'
    }).join("")}</div>`
);

app.modules.quickFinder = quickFinder.createInitInScope('.js-quickfinder', {},
    (data) => `<div class="js-quickfinder__childs row justify-content-center">${Object.keys(data).map(function (key) {
        return '<div class="col-md-2">' +
            '<div class="select form-group"><label class="form-control-label floating-label" for="' + data[key].name + data[key].id + '">Kind ' + data[key].id + '</label>' +
            '<select name="ca0[]" id="' + data[key].name + data[key].id + '" name="' + data[key].name + data[key].id + '" class="form-control js-floating-label">' +
            '<option class="d-none" value="" selected=""></option>' +
            '<option value="0">&lt;1 Jahre</option>' +
            '<option value="1">1 Jahre</option>' +
            '<option value="2">2 Jahre</option>' +
            '<option value="3">3 Jahre</option>' +
            '<option value="4">4 Jahre</option>' +
            '<option value="5">5 Jahre</option>' +
            '<option value="6">6 Jahre</option>' +
            '<option value="7">7 Jahre</option>' +
            '<option value="8">8 Jahre</option>' +
            '<option value="9">9 Jahre</option>' +
            '<option value="10">10 Jahre</option>' +
            '<option value="11">11 Jahre</option>' +
            '<option value="12">12 Jahre</option>' +
            '<option value="13">13 Jahre</option>' +
            '<option value="14">14 Jahre</option>' +
            '<option value="15">15 Jahre</option>' +
            '<option value="16">16 Jahre</option>' +
            '<option value="17">17 Jahre</option>' +
            '</select></div></div>'
    }).join("")}</div>`
);

import * as rangeSlider from './scripts/range-slider';
app.modules.rangeSlider = rangeSlider.initInScope;

// import {createRangeSlider} from '@elements/range-slider';
// app.modules.rangeSlider = createRangeSlider($('.js-range-slider'), {
//     base: '.js-range-slider',
//     slider: '.js-range-slider__slider',
//     inputMin: '.js-range-slider__min',
//     inputMax: '.js-range-slider__max'
// }, {
//     onUpdate: function(sliderObj){
//         if ( sliderObj.handle === 0 ) {
//             $(sliderObj.$element).find('[data-handle="'+ sliderObj.handle +'"]').attr('data-text', sliderObj.value.toFixed(sliderObj.decimalPlaces));
//         } else {
//             sliderObj.$element.find('[data-handle="'+ sliderObj.handle +'"]').attr('data-text', sliderObj.value.toFixed(sliderObj.decimalPlaces));
//         }
//     },
//     onSet: sliderObj => console.log('onSet', sliderObj)
// });

import * as tourMap from './scripts/tour-map';
app.tourMap = tourMap.init;

import * as tourChart from './scripts/tour-chart';
app.tourChart = tourChart.init;

import * as ribbonColorPicker from './scripts/ribbon-color-picker';
app.modules.ribbonColorPicker = ribbonColorPicker.initInScope;

import * as faqButtonHandler from './scripts/faq-button-handler';
app.modules.faqButtonHandler = faqButtonHandler.initInScope;

import * as footerIconSlider from './scripts/footer-icon-slider';
app.modules.footerIconSlider = footerIconSlider.initInScope;

import * as hotspot from './scripts/hotspot';
app.modules.hotspot = hotspot.initInScope;

import * as transformIn from './scripts/transform-in';
app.modules.transformIn = transformIn.initInScope;

import * as darkpage from './scripts/darkpage';
app.modules.darkpage = darkpage.initInScope;

import * as backButton from './scripts/back-button';
app.modules.backButton = backButton.initInScope;

import * as smoothScroll from './scripts/smooth-scroll';
app.modules.smoothScroll = smoothScroll.initInScope;

import * as iframeOverlay from './scripts/iframe-overlay';
app.modules.iframeOverlay = iframeOverlay.initInScope;

app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });
};

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();

    brochures();
    customIframes();
})($)

import {handleToscEventOnPageLoad, handleToscEventOnUserAction} from "./scripts/tosc-tracking";
app.modules.handleToscEventOnPageLoad = handleToscEventOnPageLoad;
app.modules.handleToscEventOnUserAction = handleToscEventOnUserAction;
