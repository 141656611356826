"use strict";

export function initInScope($scope) {
    $scope.find('.js-google-map-direction').on('submit', function (evt) {
        let $form = $(this);

        evt.preventDefault();


        console.log($form.serialize());

        // let $input = $(this).find('.js-google-map-route-planner__input');

        // generateGoogleMapsLink(
        //     $input.val(),
        //     $container.data('lat'),
        //     $container.data('lng')
        // );

        window.open('https://www.google.com/maps/dir/?api=1&'+ $form.serialize());
    });
}

// function generateGoogleMapsLink(start_address, destination_lat, destination_lng) {
//     start_address = start_address.replace(/\s/g, '+');
//
//     window.open('https://www.google.com/maps/dir/?api=1&origin=' + start_address + '&destination=' + destination_lat + ',' + destination_lng);
// }


// https://www.google.com/maps/dir/Unterach+am+Attersee,+4866/47.4117956,10.9125563/@47.5967907,9.9588319,7z/data=!3m1!4b1!4m8!4m7!1m5!1m1!1s0x4776aeff439c715b:0xf4ff63afd9d93e75!2m2!1d13.4852973!2d47.8057603!1m0
// https://www.google.com/maps/dir/Unterach+am+Attersee,+4866/47.4117956,10.9125563/@47.635368,11.6380075,9z/data=!3m1!4b1!4m9!4m8!1m5!1m1!1s0x4776aeff439c715b:0xf4ff63afd9d93e75!2m2!1d13.4852973!2d47.8057603!1m0!3e0
// https://www.google.com/maps/dir/Unterach+am+Attersee,+4866/47.4117956,10.9125563/@47.6808468,11.6411937,9z/data=!3m1!4b1!4m9!4m8!1m5!1m1!1s0x4776aeff439c715b:0xf4ff63afd9d93e75!2m2!1d13.4852973!2d47.8057603!1m0!3e3