"use strict";

export function initInScope($scope){
    $('.js-faq-block').each(function (i, block) {
        try {
            let toggleBtn = block.getElementsByClassName( 'js-faq-toggler' )[0];

            toggleBtn.addEventListener('click', function(evt) {
                if(!block.classList.contains('is-open')) {
                    block.classList.add('is-open');
                } else {
                    block.classList.remove('is-open');
                }
            })
        } catch (e) {}
    })

    $('.faq-container').each(function (i, block) {
        try {
            $(block).find('.js-faq-block').each(function (u, faqBlock) {
                if (u == 0) {
                    faqBlock.classList.remove('faq-block-border');
                }
            });
            let mainToggleBtn = block.getElementsByClassName( 'js-faq-main-toggler' )[0];
            let toggleArea = block.getElementsByClassName('faq-block-collection')[0];

            mainToggleBtn.addEventListener('click', function(evt) {
                if(!toggleArea.classList.contains('is-open')) {
                    toggleArea.classList.add('is-open');
                    mainToggleBtn.classList.add('is-open');
                } else {
                    toggleArea.classList.remove('is-open');
                    mainToggleBtn.classList.remove('is-open');
                }
            })
        } catch (e) {}
    })
}