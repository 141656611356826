"use strict";

function smoothScroll ($target, scrollNext) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
    let offset = $target.offset().top - 80;

    if(scrollNext) {
        offset = $target.outerHeight();
        duration = Math.abs($target.outerHeight() - window.scrollY) / 8 + 100
    }

    try {
        window.scroll({
            top: offset,
            behavior: 'smooth'
        });
    } catch (e) {
        window.scrollTo(0, offset)
    }
}

function focus ($target) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}

export function initInScope ($scope) {
    $scope.find('.js-smoothscroll__link').on('click', function (evt) {
        evt.preventDefault();
        let targetSelector = $(this).data('scroll-target') || $(this).data('scroll-next') || $(this).attr('href');
        let $target = $(targetSelector);

        //close mobile navbar
        // if(matchMedia('(max-width: 767px)').matches && $(this).parents().hasClass('navbar')) {
        //     $(this).closest('.navbar').removeClass('is-open');
        // }

        switch ($(this).data('toggle')) {
            case 'tab':
                if ($target.hasClass('in')) {
                    smoothScroll($target, function () {
                        focus($target);
                    });
                    evt.stopPropagation();
                    return;
                }

                /*scroll to parent because we can't know where the actual child is*/
                smoothScroll($target.parent(), function () {
                    focus($target);
                });

                /*update bootstrap nav classes because bootstrap doesn't handle them*/
                let navTabTrigger = $('.nav [data-toggle="tab"]').filter('[href="' + targetSelector + '"], [data-target="' + targetSelector + '"]');
                navTabTrigger.each(function () {
                    let $navItems = $(this).closest('.nav').find('li');

                    $navItems.removeClass('active')
                        .find('a').attr('aria-expanded', 'false');

                    $(this).attr('aria-expanded', 'true')
                        .parent().addClass('active');

                });
                break;
            case 'collapse':
                if ($target.hasClass('in')) {
                    smoothScroll($target, function () {
                        focus($target);
                    });
                    evt.stopImmediatePropagation();
                    return;
                }

                /*scroll to parent because we can't know where the actual child is*/
                smoothScroll($target.parent(), function () {
                    focus($target);
                });

                break;
            default:
                let scrollNext = false;
                if($(this).data('scroll-next')) {
                    scrollNext = true;
                }
                smoothScroll($target, scrollNext, function () {
                    focus($target);
                });
        }
    });

    $(window).on('load', function () {
        if(window.location.hash && $(window.location.hash).length) {
            smoothScroll($(window.location.hash), false, function () {
                focus($(window.location.hash));
            });
        }
    });
}