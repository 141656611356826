"use script";

import * as Chartist from "chartist";
import ChartistAxisTitlePlugin from "chartist-plugin-axistitle";
import {tourMapRemoveCurrentPosition , tourMapSetCurrentPosition } from "./tour-map";


export function init() {

    let $element = $('.js-tour-height-chart');

    let chartObj = {
        element: $element,
        xAxisLabel: $element.find('.js-tour-height-chart__canvas').data('label-xaxis') || null,
        yAxisLabel: $element.find('.js-tour-height-chart__canvas').data('label-yaxis') || null
    };

    fetch($element.data('chart-url'))
        .then(response => response.json())
        .then((data) => {
            if (data.success) {
                drawGraph(data.data, chartObj);
            }
        });
}

function addPolylines(poly) {
    let polyline = [];

    poly.forEach(function (elem, index) {
        polyline[index] = [elem.lat, elem.lon];
    });

    return polyline;
}

export function drawGraph(graphData, graphObj) {
    let options = {
        // fullWidth: true,
        showArea: false,
        snapToGrid: true,
        height: 370,
        axisY: {
            onlyInteger: true,
        },
        axisX: {
            labelInterpolationFnc: function skipLabels(value, index) {
                return index % Math.round(graphData.series[0].length/10) === 0 ? value : null;
            }
        },
        chartPadding: {
            top: 25,
            right: matchMedia('(min-width: 768px)').matches ? 25 : 10,
            bottom: matchMedia('(min-width: 768px)').matches ? 25 : 10,
            left: 25
        },
        plugins: [
            ChartistAxisTitlePlugin({
                axisX: {
                    axisTitle: graphObj.xAxisLabel,
                    axisClass: 'ct-axis-title',
                    offset: {
                        x: 0,
                        y: matchMedia('(min-width: 768px)').matches ? 40 : 20
                    },
                    textAnchor: 'middle'
                },
                axisY: {
                    axisTitle: graphObj.yAxisLabel,
                    axisClass: 'ct-axis-title',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    textAnchor: 'middle',
                    flipTitle: false
                }
            })
        ]
    };

    let chartHeight = new Chartist.Line('.js-tour-height-chart__canvas', graphData, options);

    chartHeight.on('draw', function (data) {
        if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
                d: {
                    begin: 50 * data.index,
                    dur: 300 * Math.pow(.9, data.index),
                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                    to: data.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            });


            if (matchMedia('(min-width: 768px)')) {
                let polylines = addPolylines(graphData.polylines);
                snapToPoints(graphObj, polylines, graphData);
            }
        }
    });
    return graphData;
}

function getCoords(elements) {
    let coords = elements.map(point => {
        let rect = point.getBoundingClientRect();
        return [rect.x, rect.y];
    });

    return coords;
}

function snapToPoints(graphObj, polylines, data) {
    console.log('snapToPoints');
    let $chart = graphObj.element.find('.js-tour-height-chart__canvas'),
        $toolTip = generateTooltip($chart),
        closestElement,
        isCombinedWithTourMap = $chart.attr('data-combined-with-tour-map') || false;

    $chart.on("mousemove", function (evt) {
        //get closest element
        let distances = [];
        getCoords(Array.from(document.querySelectorAll('.ct-point'))).forEach(pointCoord => {
            let distance = Math.hypot(pointCoord[0] - parseInt(evt.clientX));
            distances.push(parseInt(distance));
        });

        let closestElementIndex = distances.indexOf(Math.min(...distances));
        closestElement = Array.from(document.querySelectorAll('.ct-point'))[closestElementIndex];

        graphObj.element.find('.ct-point').removeClass('ct-point-hover');
        $(closestElement).addClass('ct-point-hover');

        placeTooltip($toolTip, closestElement, $chart, data, closestElementIndex);

        if(isCombinedWithTourMap) {
            tourMapRemoveCurrentPosition();
            tourMapSetCurrentPosition(polylines[closestElementIndex][0], polylines[closestElementIndex][1]);
        }
    });

    $chart.on("mouseleave", function () {
        $toolTip.hide();
    });
}

function generateTooltip($chart) {
    $chart.find('.ct-tooltip').remove();
    let $toolTip = $chart
        .append('<div class="ct-tooltip"></div>')
        .find('.ct-tooltip')
        .hide();

    return $toolTip;
}

export function placeTooltip($toolTip, point, $chart, data, index) {
    let xAxisLabel = $chart.attr('data-label-xAxis'),
        yAxisLabel = $chart.attr('data-label-yAxis');

    let heightValue = $(point).attr('ct:value') || '0',
        lengthValue = data.labels[index] || '0',
        tooltipText = yAxisLabel + ": " + heightValue + "m<br/>" + xAxisLabel + ": " + lengthValue + "km";

    $toolTip.html(tooltipText).show();

    $toolTip.css({
        left: (point.getBoundingClientRect().x) - ($toolTip.width() / 2 + $chart.offset().left + 3),
        top: ($(point).attr('y1')) - $toolTip.height() - 20
    });
}