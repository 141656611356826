"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-circle-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $this = $(this);

            $this.on('init', function(event, slick){
                let beforeIndex = $this.find('.slick-slide.slick-current').index() - 1;
                let nextIndex = $this.find('.slick-slide.slick-current').index() + 1;

                $this.find('.slick-slide').eq(beforeIndex).addClass('is-near-current');
                $this.find('.slick-slide').eq(nextIndex).addClass('is-near-current');
            });

            $this.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                dotsClass: 'sr-only',
                slidesToShow: 3,
                initialSlide: $this.find('.slider-item').length >= 5 ? 3 : 1,
                infinite: false,
                centerMode: true,
                centerPadding: "0",
                swipeToSlide: true,
                slidesToScroll: 1,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            $this.on('beforeChange', function(event, slick, nextSlide, currentSlide){
                $(slick.$slides).each(function () {
                    $(this).removeClass('is-near-current');
                });

                $(slick.$slides[currentSlide - 1]).addClass('is-near-current');
                $(slick.$slides[currentSlide + 1]).addClass('is-near-current');
            });
        });
    });
}
