"use strict";

export function initInScope($scope){
   let form = document.getElementById("job-form");

   if (form) {
       setTimeout(() => {
           form.querySelector("#telphone").required = false;
           form.querySelector("#telphone").disabled = true;
       }, "5000");
   }
}