"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-range-slider');
    import('nouislider').then(function (noUiSlider) {
        $slider.each(function () {
            let $this = $(this);
            let sliderElement = $this.find('.js-range-slider__slider')[0];
            let sliderElementInputEnabled = $this.find('.js-range-slider__slider.input-enabled')[0];
            if(sliderElementInputEnabled){
                addInputFields(sliderElementInputEnabled);
            }

            let min = $this.data('min-value') || 0;
            let max = Math.ceil($this.data('max-value')) || 100;
            let minInput = $this.find('.js-range-slider__min');
            let maxInput = $this.find('.js-range-slider__max');
            let decimalPlaces = 0;
            let steps = $this.data('steps') || 1;

            let startMin = min;
            let startMax = max;

            if (minInput.val()) {
                startMin = minInput.val();
            } else {
                startMin = min;
            }
            if (maxInput.val()) {
                startMax = maxInput.val();
            } else {
                startMax = max;
            }

            if ($this.data('steps') < 1) {
                decimalPlaces = $this.data('steps').toString().length - 2;
            }

            noUiSlider.create(sliderElement, {
                range: {
                    'min': [min],
                    'max': [max]
                },
                start: [startMin, startMax],
                step: steps,
                connect: true
            });

            sliderElement.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];

                if ( handle === 0 ) {
                    $this.find('.js-range-slider__val-min').text((value));
                    minInput.val(value.toFixed(decimalPlaces));
                    $this.find('[data-handle="'+ handle +'"]').attr('data-text', value.toFixed(decimalPlaces));
                    if($this.find('.js-range-slider-input__min').length){
                        $this.find('.js-range-slider-input__min')[0].value = value.toFixed(decimalPlaces);
                    }
                } else {
                    $this.find('.js-range-slider__val-max').text((value));
                    maxInput.val(value.toFixed(decimalPlaces));
                    $this.find('[data-handle="'+ handle +'"]').attr('data-text', value.toFixed(decimalPlaces));
                    if($this.find('.js-range-slider-input__max').length){
                        $this.find('.js-range-slider-input__max')[0].value = value.toFixed(decimalPlaces);
                    }
                }
            });

            for (const [key, rangeInput] of Object.entries($this.find('.js-range-slider__input'))) {
                if(typeof rangeInput.addEventListener === 'function'){
                    rangeInput.addEventListener('input', function (event) {
                        let range = sliderElement.noUiSlider.get(true);
                        const value = event.target.value;
                        if(rangeInput.classList.contains('js-range-slider-input__min')){
                            range = [Number(value), Number(range[1])];
                        } else if(rangeInput.classList.contains('js-range-slider-input__max')){
                            range = [Number(range[0]), Number(value)];
                        }
                        sliderElement.noUiSlider.set(range);
                    })
                }
            }

            sliderElement.noUiSlider.on('set', function(values, handle) {
                if (handle === 0) {
                    minInput.trigger('change');
                } else {
                    maxInput.trigger('change');
                }
            });

        });
    });
}

function addInputFields(sliderElement, startMin = 0, startMax = 10) {
    // Create the input field for the minimum value
    let minInput = document.createElement('input');
    minInput.type = 'number';
    minInput.className = 'js-range-slider-input__min js-range-slider__input';
    minInput.placeholder = '0';
    minInput.value = Number(startMin).toFixed();

    // Create the input field for the maximum value
    let maxInput = document.createElement('input');
    maxInput.type = 'number';
    maxInput.className = 'js-range-slider-input__max js-range-slider__input';
    maxInput.placeholder = 'Max value';
    maxInput.value = Number(startMax).toFixed();

    // Append the input fields to the parent container
    sliderElement.insertAdjacentElement('beforebegin', minInput);
    sliderElement.insertAdjacentElement('afterend', maxInput);
}
