"use strict";
let sliderId = 0;

export function initInScope ($scope) {
    let $gallerySlider = $scope.find('.js-thumb-slider');

    import('slick-carousel').then(function () {
        $gallerySlider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-thumb-slider__main');
            let $thumbSlider = $container.find('.js-thumb-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'thumb-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

            $mainSlider.slick({
                asNavFor: $thumbSlider && $thumbSlider.length ? '#thumb-slider__thumb-' + id : false,
                dots: true, /*should always be true; hide with css (accessibility)*/
                dotsClass: 'sr-only',
                slidesToShow: 1,
                swipeToSlide: true,
                infinite: false,
                slidesToScroll: 1,
                arrows: false,
                prevArrow: '<button type="button" class="slick-prev slider__arrow slider__arrow--plain" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
                nextArrow: '<button type="button" class="slick-next slider__arrow slider__arrow--plain" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            asNavFor: null,
                            dotsClass: 'slick-dots--default'
                        }
                    }
                ]
            });

            if ($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    dotsClass: 'sr-only',
                    slidesToShow: 4,
                    swipeToSlide: true,
                    arrows: true,
                    centerMode: false,
                    asNavFor: '#thumb-slider__main-' + id,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3
                            }
                        }
                    ]
                });
            }

            let $tabPaneParent = $mainSlider.closest('.tab-pane');
            if ($tabPaneParent && $tabPaneParent.length) {
                /*refresh the slider when it was hidden in a tab*/
                let $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');
                $tabTrigger.one('shown.bs.tab', function () {
                    $mainSlider.slick('setPosition');
                    $thumbSlider.slick('setPosition');
                });
            }
        });
    });
}
