"use strict";

export function initInScope($scope) {
    if (!matchMedia('(max-width: 767px)').matches) {
        return;
    }

    $scope.find('.js-toggle-offscreen').on('click', function (evt) {
        let $this = $(this);
        let $target = $($this.attr('href') || $this.data('target'));

        if (!$target.length) {
            return;
        }

        $target.toggleClass('is-open');
    });
}
