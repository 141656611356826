"use strict";

import loadScript from '@elements/load-script';

let tourMap;

function initTourMap($container) {
    let $canvas = $container.find('.js-tour-map__canvas');

    let options = {
        mapContainer: $canvas,
        disableHashHandling: true,
        view: new nefos.EmptyView(),
        requestHost: "https://tirol.mapservices.eu",
        requestBasePath: "nefos_app",
        systemName: "nefos_tirol",
        includeCSS: true
    };

    let mapObj = {
        map,
        marker: [],
        markerDetail: null,
        profileRes: null,
        profile: null,
    };

    mapObj.map = new nefos.Page(options);
    setCustomUiControls(mapObj.map, $container);

    return mapObj;
}

function showResource(tourId, map) {
    map.loadResourcesByIds({
        resourceIds:[tourId],
        callback:function(resources){

            if (!resources || resources.length == 0) {
                alert("Ressource nicht gefunden!");
                return;
            }

            let resource = resources[0];
            resource.setClusterable(false);
            map.addResourceToMap(resource);
            map.panMapTo(resource);

        }
    });

}

function setCustomUiControls(map, $map) {
    $map.find('.js-tour-map__zoom-in').on('click', function(){
        map.setMapZoomLevel( map.getMapZoomLevel()  + 1 );
    });
    $map.find('.js-tour-map__zoom-out').on('click', function(){
        map.setMapZoomLevel( map.getMapZoomLevel() - 1 );
    });
}

function loadTourMap() {
    console.log('load');
    if (!_config.tourMapApiKey) {
        return $.Deferred().reject(new Error('tour map key is not set. Please set _config.tourMapApiKey'));
    }
    return loadScript("https://tirol.mapservices.eu/nefos_app/compiled-js/" + _config.tourMapApiKey + "/stripped.js?v=1.0");
}

export function tourMapSetCurrentPosition(lat, lng) {
    tourMap.marker = new nefos.Marker({
        position: new nefos.LatLng(lat, lng)
    });
    tourMap.map.addUserMapObject( tourMap.marker );
}

export function tourMapRemoveCurrentPosition() {
    tourMap.map.removeUserMapObject(tourMap.marker);
}

export function init() {
    let $maps  = $('.js-tour-map');

    if ($maps && $maps.length) {
        $maps.each(function (index, element) {
            let $container = $(element);

            loadTourMap().then(function () {
                tourMap = initTourMap($container);
                tourMap.map.on("initialisationComplete", function(){
                    if( $container.data('tour-data') ){
                        showResource($container.data('tour-data'), tourMap.map );
                    }
                });

            });
        });
    }
}