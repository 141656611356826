"use strict";

export function initInScope($scope){
    if (document.querySelector(".loisl-widget")){
        let block = document.querySelector(".loisl-widget");
        let toggleBtn = block.querySelector('.close-icon');

        if (!localStorage.getItem("loisl-widget-deactive")){
            window.addEventListener('scroll', function() {
                if (window.scrollY > 100 && !localStorage.getItem("loisl-widget-deactive")) {
                    block.classList.remove("d-none");
                } else {
                    block.classList.add("d-none");
                }
            });
        }

        toggleBtn.addEventListener('click', function(evt) {
            block.classList.toggle('d-none');
            localStorage.setItem("loisl-widget-deactive", block.classList.contains("d-none"));
        })

        let infoToggle = document.querySelector(".info-icon");
        let infoModal = document.querySelector(".info-modal");

        let closeCross = document.querySelector(".modal-close-icon");

        infoToggle.addEventListener('click', function(evt) {
            infoModal.classList.toggle('d-none');
        })

        closeCross.addEventListener('click', function(evt) {
            infoModal.classList.add('d-none');
        })
    }
}