"use strict";

export function init() {
    $('.js-toggle-nav').on('click', function (evt) {
       evt.preventDefault();

       let $mainNav = $(this).closest('.navbar');

       if(!$mainNav.hasClass('is-open')) {
           $mainNav.addClass('is-open');

           //close search
           // let search = $mainNav.find('.navbar__search');
           // if(search.hasClass('is-open')) {
           //     search.removeClass('is-open');
           //     $mainNav.find('[data-target="#'+ search.prop('id') +'"]').removeClass('is-active');
           // }
       } else {
           $mainNav.removeClass('is-open');
       }
    });
}