"use strict";

let $currentUrl,
    $isFrontPage,
    $heroNavbar,
    $topNavbar,
    $topNavbarHeight,
    screenHeight,
    didScroll;

function scrollHandler() {
    if(!$isFrontPage) {
        return;
    }
    if($(window).scrollTop() < (screenHeight / 2)) {
        $topNavbar.addClass('d-none');
    } else {
        $topNavbar.removeClass('d-none');
    }
    if($(window).scrollTop() < screenHeight) {
        $topNavbar.removeClass('show');
    } else {
        $topNavbar.addClass('show');
    }
}

export function init() {
    if(window.screen.width < 767) {
        return;
    }

    $isFrontPage = $('.js-affix-nav-bar-container').hasClass("portal");
    $heroNavbar = $('.js-affix-hero-nav-bar');
    $topNavbar = $('.js-affix-top-nav-bar');
    screenHeight = window.screen.height;

    $heroNavbar.removeClass('d-none')

    if(!$isFrontPage) {
        $heroNavbar.addClass('d-none');
        $topNavbar.removeClass('fixed-top');
        $topNavbar.addClass('absolute-top');

        $topNavbar.addClass('show');
        $topNavbar.removeClass('d-none');
        $topNavbarHeight = $topNavbar[0].getBoundingClientRect().height;
        document.body.style.marginTop = `${$topNavbarHeight}px`;

        $topNavbar.removeClass('absolute-top');
        $topNavbar.addClass('fixed-top');
        return;
    }
    else {
        if($(window).scrollTop() < (screenHeight / 2)) {
            $topNavbar.addClass('d-none');
        }
        $(window).scroll(function () {
            didScroll = true;
        });
    }

    requestAnimationFrame(function animationFrameHandler() {
        if (didScroll) {
           scrollHandler();

            didScroll = false;
        }
        requestAnimationFrame(animationFrameHandler);
    });
}
