"use strict";

export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');
    import('video.js').then(function (videojs){
        $videos.each(function () {
            let player = videojs.default(this);

            $(this).closest('.js-video-js-container').find('.js-video-js-play').on('click', function () {
                player.play();
            });
        });
    });
}