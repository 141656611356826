"use strict";

export function initInScope($scope){
    const iconScrollWindow = document.getElementsByClassName('footer-icon__scroll-window')[0];
    const iconContainer = document.getElementsByClassName('footer-icon__container')[0];
    let icons = null;
    if (iconContainer) {
        let icons = iconContainer.getElementsByClassName('footer-icon');
    }

    let marginHorizontal;

    $(window).on('load', function() {

        if (iconScrollWindow && iconContainer && icons) {
            alignIcons();
            resizeIcons();
            iconScrollHandler();
            mouseWheelScroll();
            draggableScroll();

            window.addEventListener('resize', () => {
                alignIcons();
                resizeIcons()
            }, false);
        }
    })

    function alignIcons() {
        if (iconScrollWindow.getBoundingClientRect().width > iconContainer.getBoundingClientRect().width) {
            iconContainer.style.left = '50%';
            iconContainer.style.transform = 'translateX(-50%)';
        }
    }
    function resizeIcons() {
        const responsiveSizes = new Map([
            ['sm', {
                'numberOfIcons': 3,
                'iconWidth': 50
            }],
            ['md', {
                'numberOfIcons': 2,
                'iconWidth': 50
            }],
            ['lg', {
                'numberOfIcons': 3,
                'iconWidth': 50
            }],
            ['xl', {
                'numberOfIcons': 3,
                'iconWidth': 60
            }]
        ]);
        let sizing = responsiveSizes.get(
            window.screen.width < 768 ? 'sm'
            : window.screen.width < 992 ? 'md'
            : window.screen.width < 1200 ? 'lg'
            : 'xl'
        );

        marginHorizontal = (iconScrollWindow.offsetWidth / sizing['numberOfIcons'] - sizing['iconWidth']) / 2;
        for (let i = 0; i < icons.length; i++) {
            icons[i].style.cssText = `width: ${sizing['iconWidth']}px; margin: auto ${marginHorizontal}px;`;
        }
    }
    function iconScrollHandler() {
        const onScrollStop = callback => {
            let isScrolling;
            iconScrollWindow.addEventListener(
                'scroll',
                e => {
                    clearTimeout(isScrolling);
                    isScrolling = setTimeout(() => {
                        callback();
                    }, 150);
                },
                false
            );
        };
        onScrollStop(() => {
            let relativeChildPosition = iconScrollWindow.getBoundingClientRect().left - iconContainer.getBoundingClientRect().left;
            let scrollRight = iconScrollWindow.oldScroll > iconScrollWindow.scrollLeft ? false : true;
            let breakpointDistance = marginHorizontal * 2 + icons[0].offsetWidth;

            let leftBreakpoint = Math.floor(relativeChildPosition / breakpointDistance) * breakpointDistance;
            let rightBreakpoint = Math.ceil(relativeChildPosition / breakpointDistance) * breakpointDistance;
            let nextBreakpoint = scrollRight ? rightBreakpoint : leftBreakpoint;
            if (Math.abs(nextBreakpoint - relativeChildPosition) > (breakpointDistance * 0.7) ) {
                nextBreakpoint = scrollRight ? leftBreakpoint : rightBreakpoint;
            }

            iconScrollWindow.scroll({
                top: 0,
                left: nextBreakpoint,
                behavior: 'smooth'
            });
            iconScrollWindow.oldScroll = iconScrollWindow.scrollLeft;
        });
    }
    function mouseWheelScroll() {
        iconScrollWindow.addEventListener("wheel", event => {
            event.preventDefault();

            iconScrollWindow.scroll({
                top: 0,
                left: iconScrollWindow.scrollLeft + Math.sign(event.deltaY) * (icons[0].offsetWidth + marginHorizontal * 2),
                behavior: 'smooth'
            });
        });
    }
    function draggableScroll() {
        event.preventDefault();
        event.stopPropagation();

        let startX;
        let scrollLeft;
        let isDown;

        iconScrollWindow.addEventListener("mousedown", (e) => mouseIsDown(e));
        iconScrollWindow.addEventListener("mouseup", (e) => mouseUp(e));
        iconScrollWindow.addEventListener("mouseleave", (e) => mouseLeave(e));
        iconScrollWindow.addEventListener("mousemove", (e) => mouseMove(e));

        function mouseIsDown(e) {
            isDown = true;
            startX = e.pageX - iconScrollWindow.offsetLeft;
            scrollLeft = iconScrollWindow.scrollLeft;
        }
        function mouseUp(e) {
            isDown = false;
        }
        function mouseLeave(e) {
            isDown = false;
        }
        function mouseMove(e) {
            if (isDown) {
                const x = e.pageX - iconScrollWindow.offsetLeft;
                const walkX = (x - startX);
                iconScrollWindow.scrollLeft = scrollLeft - walkX;
            }
        }
    }
}