"use strict";

export function initInScope ($scope) {
    if(matchMedia('(min-width: 768px)').matches) {
        return;
    }

    let $hotspots = $scope.find('.js-hotspot');

    $scope.find('.js-hotspot__toggle').on('click', function (evt) {
        evt.preventDefault();
        let $hotspot = $(this).closest('.js-hotspot');

        if(!$hotspot.hasClass('is-open')) {
            $hotspots.each(function () {
                $(this).removeClass('is-open');
            });

            $hotspot.addClass('is-open');

            $('body').on('click', bodyClickHandler);
        } else {
            $hotspot.removeClass('is-open');

            $('body').off('click', bodyClickHandler);
        }
    });

    //hotspot detail position
    $(window).on('load', function () {
        $hotspots.each(function () {
            let $this = $(this),
                $detail = $(this).find('.hotspot__btn'),
                container = $this.closest('.js-hotspot-container');

            if(container.length) {
                if($this.position().top < $detail.outerHeight()) {
                    $this.addClass('hotspot--detail-bottom');
                }

                if($this.position().left + $detail.outerWidth() / 2 > container.outerWidth()) {
                    $this.addClass('hotspot--detail-left');
                }

                if($this.position().left < $detail.outerWidth() / 2) {
                    $this.addClass('hotspot--detail-right');
                }
            }
        });
    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents('.js-hotspot').length < 1) {
            evt.preventDefault();
            $('.js-hotspot').removeClass('is-open');

            $('body').off('click', bodyClickHandler);
        }
    }
}
