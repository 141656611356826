import $ from 'jquery'

export default function brochures() {
    const dataFancyBox = $('[data-fancybox]')
    const galleryButtonTrigger = $('.btn-show-gallery')
    const checkBoxesArray = $('input.checkbox-class')
    const languageSelectors = $('.language-selector')
    const brochuresForm = $('#brochuresForm')
    const formSubmitButtons = $('.form-submit-button')
    const brochuresNumber = $('.brochures-number')
    const maxWarning = $('.max-warning')
    const pageLanguage = $('#pageLanguage')
    let checked = [];

    /* fancybox initialisation */
    dataFancyBox.fancybox({
        buttons: ['close'],
        thumbs : {
            autoStart : true,
            axis      : 'y'
        }
    });

    /* fancybox button trigger */
    galleryButtonTrigger.each(function (){
        $(this).on('click', function () {
            $(this).siblings( "a" ).trigger( "click" )
        })
    }) 

    /* seting checked checkboxes to be able to use that data */
    function countCheckboxes () {
        checked = [];
        $('input.checkbox-class:checked').each(function() {
            checked.push($(this).val())
        })
        showSubmit();
        showAlert();
    }
    
    /* click listener on every checkbox */
    checkBoxesArray.each(function() {
        $(this).on('click', function () {
            countCheckboxes()
            showSubmit()
            showAlert()
        })
    })

    /* onchange listener */
    languageSelectors.each(function() {
        $(this).on('change', function() {
            attrLogic(this)
        })
    })

    /* initial state for ordering - downloading */
    function initLangSelectors() {
        languageSelectors.each(function() {
            attrLogic(this)
        })
    }

    function attrLogic(thisElement) {
        if($(thisElement).find(":selected").data("orderable")) {
            $(thisElement).parents('.options-section').find('.checkbox-button-custom').attr('disabled', false);
            let value = $(thisElement).find(":selected").data("svalue")
            $(thisElement).parents('.options-section').find('.checkbox-button-custom').attr('value', value);
        } else {
            $(thisElement).parents('.options-section').find('.checkbox-button-custom').prop('disabled', true);
            $(thisElement).parents('.options-section').find('.checkbox-button-custom').prop("checked", false);

            countCheckboxes();
        }

        if($(thisElement).find(":selected").data("downloadable")) {
            $(thisElement).parents('.options-section').find('.download-button').css('display','block')
            let href = $(thisElement).find(":selected").data("href")
            $(thisElement).parents('.options-section').find('.download-button').attr('href', href )
        } else {
            $(thisElement).parents('.options-section').find('.download-button').css('display','none')
        }
    }

    function showSubmit () {
        if ( checked.length > 0) {
            formSubmitButtons.each(function() {
                $(this).removeAttr('style');
            })
            brochuresNumber.each(function() {
                $(this).text(checked.length)
            })
        } else {
            formSubmitButtons.each(function() {
                $(this).attr('style', 'display: none !important');
            })
        }
    }

    function showAlert (){
        if ( checked.length >= 5) {
            maxWarning.removeAttr('hidden');
            disableCheckboxes();
            addTitleToCheckbox();
        }   else {
            maxWarning.attr('hidden', true);
            enableCheckboxes();
            removeTitleFromCheckbox();
        }
    }

    function disableCheckboxes() {
        const checkboxButtons = $('.checkbox-button')
        checkboxButtons.each(function() {
            let value = $(this).val()
            if( !checked.includes(value) ) {
                $(this).attr('disabled', 'disabled')
            }
        })        
    }
    
    function addTitleToCheckbox() {
        const checkboxButtons = $('.checkbox-button')
        checkboxButtons.each(function() {
            let value = $(this).val()
            if( !checked.includes(value) ) {
                $(this).parent().prop('title', "You have now earmarked 5 brochures");
            }
        })        
    }

    function enableCheckboxes() {
        const checkboxButtons = $('.checkbox-button')
        checkboxButtons.each(function() {
            let value = $(this).val()
            if( !checked.includes(value) ) {
                $(this).attr('disabled', false)
            }
        })    
    }
    
    function removeTitleFromCheckbox() {
        const checkboxButtons = $('.checkbox-button')
        checkboxButtons.each(function() {
            let value = $(this).val()
            if( !checked.includes(value) ) {
                $(this).parent().removeAttr('title')
            }
        })    
    }

    countCheckboxes()
    initLangSelectors()

    brochuresForm.on( "submit", function( event ) {
        event.preventDefault();
        const formValues=  $( this ).serialize();
        
        window.location.href = '/' + pageLanguage.html() + '/brochure-checkout?' + formValues
    });


    
}

