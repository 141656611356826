import $ from 'jquery'

export default function customIframes() {
    const infrastructuresTaxisIframe = $('#infrastructuresTaxisIframe')
    const infrastructuresPublicPoolsIframe = $('#infrastructuresPublicPoolsIframe')
    let iFrameAutoHeight;

    if( !infrastructuresTaxisIframe && !infrastructuresPublicPoolsIframe) {
        return 
    }

    function readSetHeight(iFrame) {
        let shadow = iFrame.contents().find('#tosc5target')[0].children[0].shadowRoot;
        let infrastructures = shadow.querySelector('.infrastructures-page')
        
        let iFrameHeight = $(infrastructures).height() + 64
        iFrame.height(iFrameHeight)        
    } 

    function heightIntervalSetter(iFrame) {
        iFrameAutoHeight = setInterval(()=> {
            readSetHeight(iFrame)
        },500)
    }

    setTimeout(() => {
        clearTimeout(iFrameAutoHeight);
    }, 30000)   
    
    if (infrastructuresTaxisIframe.length) {
        heightIntervalSetter(infrastructuresTaxisIframe);
    }   else if (infrastructuresPublicPoolsIframe.length) {
        heightIntervalSetter(infrastructuresPublicPoolsIframe); 
    }

    $( window ).resize(function() {
        if (infrastructuresTaxisIframe.length) {
            clearTimeout(iFrameAutoHeight);
            heightIntervalSetter(infrastructuresTaxisIframe);
            setTimeout(() => {
                clearTimeout(iFrameAutoHeight);
            }, 5000) 
        }   else if (infrastructuresPublicPoolsIframe.length) {
            clearTimeout(iFrameAutoHeight);
            heightIntervalSetter(infrastructuresPublicPoolsIframe);
            setTimeout(() => {
                clearTimeout(iFrameAutoHeight);
            }, 5000) 
        }
    });
}

