"use strict";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope ($scope) {
    $scope.find('.js-svg-percentage').each(function () {
        let $this = $(this),
            $progress = $this.find('.js-svg-percentage__progress');

        let c = Math.PI*($progress.attr('r')*2);

        onEnterViewPort($this, function () {
            $this.addClass('is-animated');
            $progress.css({ strokeDashoffset: ((100 - $this.data('percent'))/100)*c});
        }, {offset: -$this.outerHeight()});
    });
}