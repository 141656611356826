"use strict";

let $darkpage;

export function initInScope ($scope) {
    $darkpage = $scope.find('.js-darkpage');
    showDarkpage();

    $darkpage.on('hidden.bs.modal', function (e) {
        sessionStorage.setItem('darkpage-dismissed', true);
    });

    $darkpage.find('.js-darkpage__close').on('click', function () {
        console.log('click');
        $darkpage.modal('hide');
    });
}

function showDarkpage({force} = {}) {
    if (force) {
        $darkpage.modal('show');
    }

    let showDarkpage = true;
    try {
        showDarkpage = !sessionStorage.getItem('darkpage-dismissed');
    } catch (e) {}

    if (showDarkpage) {
        $darkpage.modal('show');
    }
}