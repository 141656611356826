"use strict";

export function initInScope ($scope) {
    let $overlayToggle = $scope.find('.js-iframe-overlay__toggle');

    $overlayToggle.on('click', function () {
        let $container = $(this).closest('.js-iframe-overlay');
        if (!$container.hasClass('is-active')) {
            $container.addClass('is-active');
        }
    });
}
